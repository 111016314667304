<template>
  <div class="signal-popup" >
    <div class="protected-popup-row">
      <div>Землетрясение:</div>
    </div>
    <div class="protected-popup-row">
      <div>Дата</div>
      <div>{{signal.earthquakeDate ? signal.earthquakeDate : '-'}}</div>
    </div>
    <div class="protected-popup-row">
      <div>Широта</div>
      <div>{{signal.latitude ? signal.latitude : '-'}}</div>
    </div>
    <div class="protected-popup-row">
      <div>Долгота</div>
      <div>{{signal.longitude ? signal.longitude : '-'}}</div>
    </div>
    <div class="protected-popup-row">
      <div>глубина</div>
      <div>{{signal.depth ? signal.depth : '-'}}</div>
    </div>
    <div class="protected-popup-row">
      <div>Магнитуда</div>
      <div>{{signal.magnitude}}</div>
    </div>
  </div>
</template>

<script>

  export default {
    name: 'protectedPointVuew',
    props: {
      signal: Object
    },
  }
</script>

<style scoped>
  .signal-popup{
    background-color: red;
    padding: 15px;
    gap: 5px;

  }

  .protected-popup-row {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    color: white;
    font-size: 18px;
  }
</style>