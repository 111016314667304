import Point from 'ol/geom/Point.js';
import VectorLayer from 'ol/layer/Vector.js';
import VectorSource from 'ol/source/Vector.js';
import {pointRedStyle} from "./customStyles";
import Feature from "ol/Feature";

export function getSignalLayer(signal) {
  let points = []

  let features = []
  console.log('signal', signal)

  if (signal) {
    points.push(signal)

    points.forEach( p => {
      let feature = new Feature({
        name: p.id,
        type: 'Feature',
        geometry: new Point([p.longitude, p.latitude]),
        properties: {
          ...signal
        },
      })
      features.push(feature)
    })

  }

  const vectorSource = new VectorSource({
    features: features
  });

  return new VectorLayer({
    visible: true,
    source: vectorSource,
    style: pointRedStyle,
    zIndex: 2,
    wrapX: true
  });
}

