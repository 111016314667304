import Point from 'ol/geom/Point.js';
import VectorLayer from 'ol/layer/Vector.js';
import VectorSource from 'ol/source/Vector.js';
import {pointGreenStyle} from "./customStyles";
import Feature from "ol/Feature";

export function getCoastalLGaugesLayer(gauges) {
  let points = []

  let features = []
  console.log('gauges', gauges)

  if (gauges && gauges.length) {
    points.push(...gauges)

    points.forEach( p => {
      let feature = new Feature({
        name: p.name,
        type: 'Feature',
        geometry: new Point([p.longitude, p.latitude]),
        properties: {
          name: p.name,
          type: p.type,
          latitude: p.latitude,
          longitude: p.longitude,
          code: p.code,
          height: p.height,
        },
      })
      features.push(feature)
    })

  }

  const vectorSource = new VectorSource({
    features: features
  });

  return new VectorLayer({
    visible: true,
    source: vectorSource,
    style: pointGreenStyle,
    zIndex: 2,
    wrapX: true
  });
}

