<template>
  <div class="protected-popup" :style="{'background-color': gauge.type === 'DART' ? 'blue' : 'green'}">
    <div class="protected-popup-row">
      <div>Датчик моря:</div>
      <div>{{gauge.name}}</div>
    </div>
    <div class="protected-popup-row">
      <div>Код пункта</div>
      <div>{{gauge.code ? gauge.code : '-'}}</div>
    </div>
    <div class="protected-popup-row">
      <div>Широта</div>
      <div>{{gauge.latitude ? gauge.latitude : '-'}}</div>
    </div>
    <div class="protected-popup-row">
      <div>Долгота</div>
      <div>{{gauge.longitude ? gauge.longitude : '-'}}</div>
    </div>
    <div class="protected-popup-row">
      <div>Высота</div>
      <div>{{gauge.height ? gauge.height : '-'}}</div>
    </div>
    <div class="protected-popup-row">
      <div>Тип</div>
      <div>{{gauge.type}}</div>
    </div>
  </div>
</template>

<script>

  export default {
    name: 'protectedPointVuew',
    props: {
      gauge: Object
    },
  }
</script>

<style scoped>
  .protected-popup {
    background-color: green;
    padding: 15px;
    gap: 5px;

  }

  .protected-popup-row {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    color: white;
    font-size: 18px;
  }
</style>