<template>
  <div>
    <div ref="map-root" id="map"
         style="width: 100%; height: 50vh; border: 1px solid black; background-color: #51B5D3">
      <div id="info">
        <protected-point-popup v-if="!!protectedPoint && protectedPoint.region" :key="protectedPoint.region + protectedPoint.waveTime" :protected-point="protectedPoint" :signal="signal"/>
        <gauges-popup v-if="!!selectedGauge" :key="selectedGauge.code" :gauge="selectedGauge"/>
        <signal-popup v-if="!!selectedSignal" :key="selectedSignal.id" :signal="selectedSignal"/>
      </div>
    </div>
    zoom {{zoom}} center {{center}} feature: {{feature}}
    <div id="popup" class="ol-popup">
      <a href="#" id="popup-closer" class="ol-popup-closer"></a>
      <div id="popup-content"></div>
    </div>
  </div>
</template>

<script>
  // importing the OpenLayers stylesheet is required for having
  import 'ol/ol.css'

  // import Point from 'ol/geom/Point';
  //
  // import Feature from 'ol/Feature.js';
  // import Overlay from 'ol/Overlay'
  import GeoJSON from 'ol/format/GeoJSON.js';
  import Map from 'ol/Map.js';
  import View from 'ol/View.js';
  import { Vector as VectorSource} from 'ol/source.js';
  import { Vector as VectorLayer} from 'ol/layer.js';
  import {MultiPolygon, Polygon} from 'ol/geom'


  import {polygonGrayStyle} from "../utils/map/customStyles";
  // import {testPointLayer} from "../utils/map/testPointLayer";
  import {mapGetters} from "vuex";
  import {getProtectedPointLayerFromCalc, getProtectedPointLayerFromLocations} from "../utils/map/protectedPointsLayer";


  import ProtectedPointPopup from '../components/protectedPointPopup'
  import GaugesPopup from '../components/gaugesPopup'
  import SignalPopup from '../components/signalPopup'
  import {getDartGaugesLayer} from "../utils/map/gaugeDartLayer";
  import {getCoastalLGaugesLayer} from "../utils/map/gaugeCoastalLayer";
  import {getSignalLayer} from "../utils/map/signalLayer";
  // import {getGeotiffLayer} from "../utils/map/geoTiffLayer";

  // import OSM from 'ol/source/OSM.js';
  // import TileLayer from 'ol/layer/Tile.js';
  let currentFeature;

  export default {
    name: 'MapContainer',
    components: {ProtectedPointPopup, GaugesPopup, SignalPopup},
    props: {
      signal: Object
    },
    data() {
      return {
        protectedPoint: null,
        selectedGauge: null,
        selectedSignal: null,
        feature: '',
        gauge: null,
        map: null,
        dartGaugesLayer: null,
        coastalGaugesLayer: null,
        basePointsLayer: null,
        calcPointsLayer: null,
        signalLayer: null,

        dartLoaded: false,
        coastalLoaded: false,

        signalLayerEnabled: true,
        baseLayerEnabled: true,
        dartLayerEnabled: true,
        coastalLayerEnabled: true
      }
    },
    watch: {
      getCalcResults(newVal) {
        if (newVal && newVal.length) {
          this.signalLayer = getSignalLayer(this.signal)
          this.calcPointsLayer = getProtectedPointLayerFromCalc(newVal)
        }
        this.formLayers()

      },
      getGauges(newVal) {
        if (!newVal) return;
        console.log('getGauges', newVal)
        let coastalGauges = newVal.filter(g => g.type === 'COASTAL')
        let dartGauges = newVal.filter(g => g.type === 'DART')
        this.dartGaugesLayer = getDartGaugesLayer(dartGauges)
        this.coastalGaugesLayer = getCoastalLGaugesLayer(coastalGauges)
        // this.map.addLayer(getProtectedPointLayerFromCalc(newVal))
        if (this.map && !this.coastalLoaded) {
          this.coastalLoaded = true
          this.map.addLayer(this.coastalGaugesLayer)
        }
        if (this.map && !this.dartLoaded) {
          this.dartLoaded = true
          this.map.addLayer(this.dartGaugesLayer)
        }
      }
    },
    computed: {
      ...mapGetters(['getCalcResults', 'getGauges']),
      zoom() {
        // console.log(1)
        return (this.map && this.map.getView()) ? this.map.getView().getZoom() : ''
      },
      center() {
        // console.log(2)
        return (this.map && this.map.getView()) ? this.map.getView().getCenter() : ''
      }
    },
    methods: {
      formLayers() {
        if (!this.map) return;
        // this.map.getLayers().forEach(layer => {
        //   this.map.removeLayer(layer)
        // });
        if (this.baseLayerEnabled) {
          if (this.calcPointsLayer) {
            if (this.basePointsLayer) this.map.removeLayer(this.basePointsLayer)
            if (!(this.map.getLayers().getArray().some(layer => layer === this.calcPointsLayer))) this.map.addLayer(this.calcPointsLayer);
          } else {
            if (!(this.map.getLayers().getArray().some(layer => layer === this.basePointsLayer))) this.map.addLayer(this.basePointsLayer);
          }
        }

        if (this.signalLayerEnabled) {
          if (!(this.map.getLayers().getArray().some(layer => layer === this.signalLayer))) this.map.addLayer(this.signalLayer);
        } else {
          this.map.removeLayer(this.signalLayer)
        }

        if (this.dartLayerEnabled) {
          if (!(this.map.getLayers().getArray().some(layer => layer === this.dartGaugesLayer))) this.map.addLayer(this.dartGaugesLayer);
        } else {
          this.map.removeLayer(this.dartGaugesLayer)
        }

        if (this.coastalLayerEnabled) {
          if (!(this.map.getLayers().getArray().some(layer => layer === this.coastalGaugesLayer))) this.map.addLayer(this.coastalGaugesLayer);
        } else {
          this.map.removeLayer(this.coastalGaugesLayer)
        }

      },
      checkAntimeridian(feature) {

        const coordinates = feature.getGeometry().getCoordinates();
        // console.log('coordinates', JSON.stringify(coordinates))
        let ultraPositive = false
        let ultraNegative = false
        coordinates.map(ring => {
          ultraPositive = ultraPositive || ring.some(point => point[0] > -21)
          ultraNegative = ultraNegative || ring.some(point => point[0] < -21)
        });
        return ultraNegative
      },
      rewriteLongitudeMulti(multi) {
        const coordinates = multi.getCoordinates();
        // console.log('coordinates multi', JSON.stringify(coordinates))
        const updatedCoordinates = coordinates.map(ring => {
          let ultraPositive = false
          let ultraNegative = false
          return ring.map(polygon => {
            ultraPositive = ultraPositive || polygon.some(point => point[0] > -21)
            ultraNegative = ultraNegative || polygon.some(point => point[0] < -21)

            return polygon.map(point => {
              // Изменяем долготу точки
              let longitude = point[0]
              if (ultraNegative ) {
                point[0] = 360 + longitude; // Пример: добавляем 10 градусов долготы
              } else if (longitude < -21) {
                point[0] = 360 + longitude; // Пример: добавляем 10 градусов долготы
              } else {
                point[0] = longitude
              }
              // point[0] = longitude; // Пример: добавляем 10 градусов долготы
              return point;
            })
          });
        });
        // console.log('updatedCoordinates multi', JSON.stringify(updatedCoordinates))
        multi.setCoordinates(updatedCoordinates);
        return multi;
      },
      rewriteLongitude(polygon) {
        const coordinates = polygon.getCoordinates();
        // console.log('coordinates', JSON.stringify(coordinates))
        let ultraPositive = false
        let ultraNegative = false
        coordinates.map(ring => {
          ultraPositive = ultraPositive || ring.some(point => point[0] > -21)
          ultraNegative = ultraNegative || ring.some(point => point[0] < -21)
        });

        const updatedCoordinates = coordinates.map(ring => {
          return ring.map(point => {
            // Изменяем долготу точки
            let longitude = point[0]
            if (ultraNegative) {
              point[0] = 360 + longitude; // Пример: добавляем 10 градусов долготы
            } else if (longitude < -21) {
              point[0] = 360 + longitude; // Пример: добавляем 10 градусов долготы
            } else {
              point[0] = longitude
            }

            return point;
          });
        });

        // console.log('updatedCoordinates', JSON.stringify(updatedCoordinates))
        polygon.setCoordinates(updatedCoordinates);
        return polygon;
      },
      displayFeatureInfo (pixel, target, that) {
        const feature = target.closest('.ol-control')
          ? undefined
          : this.map.forEachFeatureAtPixel(pixel, function (feature) {
            return feature;
          });

        if (!feature) {
          const info = document.getElementById('info');
          info.style.visibility = 'hidden';
          that.protectedPoint = null
          that.selectedGauge = null
          that.selectedSignal = null
          // this.feature = ''
          currentFeature = null
          return
        }

        const info = document.getElementById('info');

        let name = feature.get('name') ? feature.get('name') : ''
        // console.log('feature', feature, name, feature.get('properties'), pixel, this.feature)
        let mapElement = document.getElementById("map");
        that.feature = name
        info.style.left = pixel[0] + mapElement.offsetLeft + 'px';
        info.style.top = pixel[1] + mapElement.offsetTop + 'px';
        if (feature !== currentFeature) {
          info.style.visibility = 'visible';
          // info.innerHtml = `<div></div>`;
          // this.feature = name
        }
        // console.log(this.feature)
        if (feature.get('properties') && feature.get('properties').region) {
          console.log('protected point', feature.get('properties'))
          that.protectedPoint = feature.get('properties') && feature.get('properties')['region'] ? feature.get('properties') : {}
        } else if (feature.get('properties') && feature.get('properties').code) {
          console.log('on gauge move', feature.get('properties'))
          that.selectedGauge = feature.get('properties') && feature.get('properties')['code'] ? feature.get('properties') : {}
        } else if (feature.get('properties') && feature.get('properties').magnitude) {
          console.log('on gauge move', feature.get('properties'))
          that.selectedSignal = feature.get('properties') && feature.get('properties')['magnitude'] ? feature.get('properties') : {}
        } else {
          that.protectedPoint = null
          that.selectedGauge = null
          that.selectedSignal = null
        }

        currentFeature = feature;
      },
      unhide() {
        if (this.map) this.map.updateSize()
      },
      init() {
        console.log('загрузка карты')
        // this is where we create the OpenLayers map
        this.map = null
        let that = this


        // fetch('/World_Database_Protected_Areas_Polygons.json')
        // fetch('/world.geojson')
        fetch('/amgeo.geojson')
          .then(response => response.json())
          .then(geojson => {
            console.log('type of geojson', typeof geojson, JSON.stringify(geojson))
            let features2 = new GeoJSON().readFeatures(geojson)

            // features2 = features2.filter(f => that.checkAntimeridian(f))

            features2.forEach(feature => {
              if (feature.getGeometry() instanceof MultiPolygon) {
                const polygon = feature.getGeometry();
                const updatedPolygon = that.rewriteLongitudeMulti(polygon);
                feature.setGeometry(updatedPolygon);
              } else if (feature.getGeometry() instanceof Polygon) {
                const polygon = feature.getGeometry();
                const updatedPolygon = that.rewriteLongitude(polygon);
                feature.setGeometry(updatedPolygon);
              }
            });

            const vectorSource = new VectorSource({
              features: new GeoJSON().readFeatures(geojson),
            });
            const vectorSource2 = new VectorSource({
              features: features2,
            });

            // vectorSource.addFeature(new Feature(new Circle([5e6, 7e6], 1e6)));

            const baseLayer = new VectorLayer({
              source: vectorSource,
              // style: this.styleFunction,
              zIndex: 0,
              style: polygonGrayStyle,
              dataProjection: 'EPSG:4326', // Исходная проекция (долгота/широта)
              featureProjection: 'EPSG:3857' // Целевая проекция (Web Mercator)              // wrapX: true
            });

            const baseLayer2 = new VectorLayer({
              source: vectorSource2,
              // style: this.styleFunction,
              zIndex: 0,
              style: polygonGrayStyle,
              // wrapX: true
            });

            this.basePointsLayer = getProtectedPointLayerFromLocations([])

            // getProtectedPointLayerFromLocations(getLocation())

            // console.log(baseLayer)
            // let tileLayer = new TileLayer({
            //   source: new OSM(),
            // })

            this.map = new Map({
              // the map will be created using the 'map-root' ref
              target: this.$refs['map-root'],
              // the map view will initially show the whole world
              view: new View({
                // projection: 'EPSG:4326',
                zoom: 20,
                minZoom: 20,
                maxZoom: 26,
                center: [ 220.3005262757033, 30.14270051696026],
                extent: [-20, -90, 340, 90],
                wrapX: true,
                // projection: 'EPSG:4326', //added recently
                //
                // zoom: 4,
// extent: [-20, -90, 340, 90],
              }),
              // projection: 'EPSG:3857', // Web Mercator
              // extent: [-20037508.342789244, -20037508.342789244, 20037508.342789244, 20037508.342789244],
              bgcolor: '#0feeef',
            })

            // this.map.addLayer(tileLayer);
            this.map.addLayer(baseLayer);
            this.map.addLayer(baseLayer2);
            // this.map.addLayer(exampleLayer);
            // this.map.addLayer(pointLayer);
            // this.map.addLayer(testPointLayer);
            // this.map.addLayer(getGeotiffLayer());
            // this.map.addLayer(tokyoLayer)

            // var point = new Point([220, 45]);
            // // Create a feature with the point geometry
            //
            // var feature2 = new Feature({
            //   name: 'super point',
            //   geometry: point
            // });
            // feature2.setStyle(
            //   styles[styleKeys['triangle']],
            // );
            // // Create a vector layer to hold the point feature
            //
            // var vectorLayer2 = new VectorLayer({
            //   source: new VectorSource({
            //     features: [feature2],
            //   }),
            //   style:[styles[styleKeys['triangle']]]
            // });
            // // add the vectorLater to the map:
            //
            //   this.map.addLayer(vectorLayer2);
            // this.map.getView.fit
            console.log('Карта добавлена на слой')


            // this.map.addOverlay(this.setOverlay());

            this.map.on('pointermove', function (evt) {
              if (evt.dragging) {
                const info = document.getElementById('info');
                info.style.visibility = 'hidden';
                currentFeature = undefined;
                return;
              }
              const pixel = that.map.getEventPixel(evt.originalEvent);
              // console.log('orig event', event.originalEvent)
              that.displayFeatureInfo(pixel, evt.originalEvent.target, that);
            });

            this.map.getTargetElement().addEventListener('pointerleave', function () {
              currentFeature = undefined;
              const info = document.getElementById('info');
              info.style.visibility = 'hidden';
            });

            // this.map.on('ready', () => {
            //   console.log('Карта готова к работе!');
            if (that.dartGaugesLayer && !that.dartLoaded) {
              that.dartLoaded = true
              that.map.addLayer(that.dartGaugesLayer)
            }
            if (that.coastalGaugesLayer && !that.coastalLoaded) {
              that.coastalLoaded = true
              that.map.addLayer(that.coastalGaugesLayer)
            }
            // });

          })
          .catch(e => console.error('geojson load failed', e))
      },
      // setOverlay() {
      //   var container = document.getElementById('popup');
      //    var overlay = new Overlay({
      //     element: container,
      //     autoPan: true,
      //     autoPanAnimation: {
      //       duration: 250
      //     }
      //   });
      //   return overlay
      // }
    },
    mounted() {
      this.init()
    }
  }
</script>

<style>
  #viewDiv {
    width: 100%;
    height: 100%;
  }

  #info {
    border: 1px solid white;
    position: absolute;
    display: inline-block;
    height: auto;
    width: auto;
    z-index: 100;
    /*background-color: #333;*/
    /*color: #ffffff;*/
    text-align: center;
    border-radius: 4px;
    /*padding: 15px;*/
    left: calc(50% + 100px);

    transform: translateX(3%);
    pointer-events: none;
  }
</style>
