<template>
  <div class="protected-popup">
    <div class="protected-popup-row">
      <div>Тип</div>
      <div>{{protectedPoint.region}}</div>
    </div>
    <div class="protected-popup-row">
      <div>Защищаемый пункт</div>
      <div>{{protectedPoint.name}}</div>
    </div>
    <div class="protected-popup-row">
      <div>Смещение от времени сигнала (с)</div>
      <div>{{protectedPoint.waveTime ? protectedPoint.waveTime : '-'}}</div>
    </div>
    <div class="protected-popup-row">
      <div>Время прихода волны</div>
      <div>{{protectedPoint.waveTime ? getWaveTime : '-'}}</div>
    </div>
    <div class="protected-popup-row">
      <div>Максимальная высота волна (м)</div>
      <div>{{protectedPoint.maxWaveHeight ? protectedPoint.maxWaveHeight : '-'}}</div>
    </div>
    <div class="protected-popup-row">
      <div>Минимальная высота волны (м)</div>
      <div>{{protectedPoint.minWaveHeight ? protectedPoint.minWaveHeight : '-'}}</div>
    </div>
  </div>
</template>

<script>
  import {getDateTime} from "../utils/time";

  export default {
    name: 'protectedPointVuew',
    props: {
      signal: Object,
      protectedPoint: Object
    },
    computed: {
      getWaveTime() {
        if (!this.gauge) return '-'
        let offset = this.gauge.waveTime
        if (offset !== 0 && !offset) return '';

        let date = new Date(this.signal.earthquakeDate)
        // let translate = getDateTime(date, true)
        if (offset) {
          date.setSeconds(date.getSeconds() + +offset)
        }
        return getDateTime(date, false) + ' ВСВ'// + ' (' + translate + ' + '+ offset + ')'
      }
    }
  }
</script>

<style scoped>
  .protected-popup {
    background-color: green;
    padding: 15px;
    gap: 5px;

  }

  .protected-popup-row {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    color: white;
    font-size: 18px;
  }
</style>