import {Fill, Stroke, Style} from 'ol/style.js';
import {RegularShape} from "ol/style";
import CircleStyle from "ol/style/Circle";

// let red = '#FF0000'

// const stroke = new Stroke({color: 'black', width: 2});
// const fill = new Fill({color: 'red'});

export function polygonGrayStyle() {
  return new Style({
    fill: new Fill({color: '#D7D7D7'}),
    stroke: new Stroke({color: 'white', width: 1}),
  })
}

export function pointYellowStyle() {
  return new Style({
    'image': new RegularShape({
      fill: new Fill({color: 'yellow'}),
      stroke: new Stroke({color: 'black', width: 1}),
      points: 3,
      radius: 10,
      angle: 1.53
    }),
  })
}

export function pointBlueStyle() {
  return new Style({
    'image': new RegularShape({
      fill: new Fill({color: '#040AF8'}),
      stroke: new Stroke({color: 'white', width: 1}),
      points: 4,
      radius: 10,
      angle: 0.78
    }),
  })
}

export function pointGreenStyle() {
  return new Style({
    'image': new RegularShape({
      fill: new Fill({color: '#008000'}),
      stroke: new Stroke({color: 'white', width: 1}),
      points: 4,
      radius: 10,
      angle: 0.78
    }),
  })
}

export function pointRedStyle() {
  return new Style({
    image: new CircleStyle({
      radius: 10, // Радиус круга
      fill: new Fill({
        color: 'red' // Красный цвет
      }),
      stroke: new Stroke({
        color: 'white', // Черный цвет обводки
        width: 1 // Ширина обводки
      })
    })
  });
}