import Point from 'ol/geom/Point.js';
import VectorLayer from 'ol/layer/Vector.js';
import VectorSource from 'ol/source/Vector.js';
import {pointYellowStyle} from "./customStyles";
import Feature from "ol/Feature";

function createFetatures(points) {

  let features = []
  points.forEach( p => {
    let feature = new Feature({
      name: p.name,
      type: 'Feature',
      geometry: new Point([p.longitude, p.latitude]),
      properties: {
        name: p.name,
        category: 'Защищаемый пункт',
        latitude: p.latitude,
        longitude: p.longitude,
        waveTime: p.waveTime,
        minWaveHeight: p.minWaveHeight,
        maxWaveHeight: p.maxWaveHeight,
        region: p.region,
      },
    })
    features.push(feature)
  })

  return features
}

export function getProtectedPointLayerFromCalc(calcResults) {
  let points = []
  //handle points
  console.log('protected point response', calcResults)

  let features = []
  if (calcResults && calcResults.length) {
    calcResults.forEach(r => {
      console.log('r', r)
      points.push(...r.results.map( res => {return {...res, region: r.type}}))
    })

    features = createFetatures(points)

  }

  const vectorSource = new VectorSource({
    features: features
  });

  return new VectorLayer({
    visible: true,
    source: vectorSource,
    style: pointYellowStyle,
    zIndex: 2
  });
}

export function getProtectedPointLayerFromLocations(locations) {
  console.log('protected point response from locations', locations)

  let features = createFetatures(locations)

  const vectorSource = new VectorSource({
    features: features
  });

  return new VectorLayer({
    visible: true,
    source: vectorSource,
    style: pointYellowStyle,
    zIndex: 2
  });
}

